import React from "react";
import {
  DivPopUp,
  DivStatusPopUp,
  NamePopUp,
  PriceValues,
  ValuePopUp,
} from "./CardsStyle";

export function PopupClient(props) {
  const infoSells = props.financialResume;
  const infoMonth = props.infoMonth;

  return (
    <DivPopUp>
      <DivStatusPopUp>
        <NamePopUp>Faturamento Dia</NamePopUp>
        <PriceValues
          displayType="text"
          value={infoSells?.totalFinancialDay?.totalSell || 0}
          decimalSeparator=","
          thousandSeparator="."
          fixedDecimalScale
          decimalScale={2}
          prefix={"R$ "}
        />
      </DivStatusPopUp>
      <DivStatusPopUp>
        <NamePopUp>Faturamento Mês</NamePopUp>
        <PriceValues
          displayType="text"
          value={infoMonth?.totalSell || 0}
          decimalSeparator=","
          thousandSeparator="."
          fixedDecimalScale
          decimalScale={2}
          prefix={"R$ "}
        />
      </DivStatusPopUp>
      <DivStatusPopUp>
        <NamePopUp>Clientes</NamePopUp>
        <ValuePopUp>{infoSells?.totalClients || 0}</ValuePopUp>
      </DivStatusPopUp>
      <DivStatusPopUp>
        <NamePopUp>Vendas do Mês</NamePopUp>
        <ValuePopUp>{infoMonth?.totalOrders || 0}</ValuePopUp>
      </DivStatusPopUp>
    </DivPopUp>
  );
}

export function PopUpProvider(props) {
  const financialEntryOrders = props.financialResumeOrders;
  const financialByMonth = props.infoMonth;

  return (
    <DivPopUp>
      <DivStatusPopUp>
        <NamePopUp>Compras</NamePopUp>
        <PriceValues
          displayType="text"
          value={financialByMonth?.totalBuyedEntry || 0}
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          fixedDecimalScale
          prefix={"R$ "}
        />
      </DivStatusPopUp>
      <DivStatusPopUp>
        <NamePopUp>Pedidos</NamePopUp>
        <PriceValues
          displayType="text"
          value={financialByMonth?.totalEntryOrders || 0}
        />
      </DivStatusPopUp>
      <DivStatusPopUp>
        <NamePopUp>Em estoque</NamePopUp>
        <PriceValues
          displayType="text"
          value={financialEntryOrders?.totalInStock || 0}
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={2}
          fixedDecimalScale
          prefix={"R$ "}
        />
      </DivStatusPopUp>
      <DivStatusPopUp>
        <NamePopUp>Fornecedores</NamePopUp>
        <PriceValues
          displayType="text"
          value={financialEntryOrders?.totalProviders || 0}
        />
      </DivStatusPopUp>
    </DivPopUp>
  );
}
