import React, { useEffect, useState } from "react";
import {
  DivOpenCashier,
  BtnOpenCashier,
  FormCash,
  TitleOpenCashier,
  DivOrgOpenCashier,
  LabelOpenCashier,
  InputCashier,
  DivBtn,
  StatusCashier,
  DivOrgLoading,
  DivOrgStatus,
  DivOrgBtn,
  InfoUsername,
} from "./OpenCashierStyle";

import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createCashier } from "../../../store/financial/cashier/cashier.actions";

export default function OpenCashier(props) {
  const infoCashier = props.infoCashier;
  const dispatch = useDispatch();
  const infoUser = useSelector((state) => state.auth);

  const [valueCashier, setValueCashier] = useState("");
  const [user, setUser] = useState({
    username: "",
    idUser: "",
  });
  const [loading, setLoading] = useState(false);
  const [statusCashier, setStatusCashier] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  const {
    handleSubmit,
  } = useForm();

  const sendCashier = async (dataCashier) => {
    setLoading(true);
    dataCashier.initialValueOpen = valueCashier;
    dataCashier.status = "aberto";
    dataCashier.idUser = user.idUser;

    // ! - ate o momento ele somente cria o caixa, mas não trata o retorno
    await dispatch(createCashier(dataCashier));
    setStatusCashier("Aberto");
    setTimeout(() => {
      setLoading(false);
      // props.getCashiersToday();
    }, 1000);
  };

  const checkCashierStatus = async (dataCashier) => {
    if (dataCashier?.cashierToday.status === "aberto") {
      setLoading(true);
      setStatusCashier("Caixa aberto");
      setValueCashier(dataCashier?.cashierToday.initialValueOpen);
      setDisableInput(true);
      setButtonDisable(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else {
      setLoading(true);
      setStatusCashier("Aguardando abrir Caixa");
      setValueCashier("");
      setDisableInput(false);
      setButtonDisable(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    checkCashierStatus(infoCashier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoCashier]);

  useEffect(() => {
    console.log(infoUser)
    if (infoUser.isAuthenticated) {
      setUser({
        username: infoUser.user.username || infoUser.user,
        idUser: infoUser.idUser,
      });
    }
  }, [infoUser]);

  return (
    <DivOpenCashier>
      <TitleOpenCashier>Abrir Caixa</TitleOpenCashier>
      <FormCash onSubmit={handleSubmit(sendCashier)}>
        <DivOrgOpenCashier>
          <LabelOpenCashier>Valor em Caixa</LabelOpenCashier>
          <InputCashier
            disabled={disableInput}
            value={valueCashier}
            placeholder="R$"
            decimalSeparator=","
            thousandSeparator="."
            fixedDecimalScale
            decimalScale={2}
            prefix={"R$"}
            onValueChange={(values, sourceInfo) => {
              setValueCashier(Number(values.value));
            }}
          />
        </DivOrgOpenCashier>
        <DivOrgOpenCashier>
          <LabelOpenCashier>Usuario</LabelOpenCashier>
          <InfoUsername>{user?.username}</InfoUsername>
        </DivOrgOpenCashier>
        <DivOrgBtn>
          <DivBtn>
            <BtnOpenCashier type="submit" disabled={buttonDisable}>
              Abrir Caixa
            </BtnOpenCashier>
          </DivBtn>

          <DivOrgStatus>
            <StatusCashier>Status: </StatusCashier>

            {loading ? (
              <DivOrgLoading>
                <ClipLoader speedMultiplier={3} color={"#000"} />
              </DivOrgLoading>
            ) : (
              statusCashier && <StatusCashier>{statusCashier}</StatusCashier>
            )}
          </DivOrgStatus>
        </DivOrgBtn>
      </FormCash>
    </DivOpenCashier>
  );
}
