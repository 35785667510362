import React, { useEffect, useRef, useState } from "react";
import {
  BtnClose,
  BtnNewOrder,
  DivOrderAnimation,
  DivOrgBtnClose,
  DivOrgBtnOrder,
  // StatusOrder,
  TitleAnimation,
  // DivOrgLoading,
  BtnPrint,
  DivOrgInfoAnimation,
  DivInfoCompany,
  DivOrgTable,
  DivOrgTitle,
  InfoCompany,
  Line,
  Table,
  TableBody,
  TableFoot,
  TableHead,
  TablePayments,
  TdPayments,
  TdTable,
  TdTableDesc,
  TdTablePrices,
  TdValues,
  ThTable,
  ThTableDesc,
  ThTablePrices,
  TitlePrint,
  TrPayments,
  TrTable,
  DivOrgInfoCompany,
  DivInfoClient,
  // BtnMail,
  PricesFormat,
  DivOrgSelect,
  SelectPaper,
  OptionsPaper,
} from "./FinisihOrderAnimationStyle";
import { useNavigate } from "react-router-dom";
import { Close } from "@styled-icons/material";
// import { ClipLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";

export default function FinishOrderAnimation(props) {
  // const orderResponse = props.trueAnimation;
  const infoFinishOrder = props.infoFinishOrder.order;
  // const orderResponse = true;
  const navigate = useNavigate();

  const [orderList, setOrderList] = useState([]);
  // const [showList, setShowList] = useState(false);
  const [typePaper, setTypePaper] = useState();

  // const [finishOrder, setFinishOrder] = useState({ message: "pendente" });
  // const [infoPrint, setInfoPrint] = useState(false);

  const printRef = useRef();
  const printOrder = useReactToPrint({
    content: () => printRef.current,

    onAfterPrint: () => {
      navigate("/comercial");
    },
  });


  const parseName = (oneName) => {
    if (oneName) {
      const firstName = oneName || "";

      var fullName = firstName.concat(" ");

      const formatName = fullName.split(" ");
      for (var i = 0; i < formatName.length; i++) {
        formatName[i] =
          formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
      }
      let result = formatName?.join(" ");

      return result;
    }
  };

  useEffect(() => {
    if (infoFinishOrder?.itemsCart.length > 0) {
      console.log(infoFinishOrder)
      setOrderList(infoFinishOrder?.itemsCart);
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoFinishOrder?.itemsCart]);

  return (
    <DivOrderAnimation
      show={props.showAnimation}
      // show={true}
    >
      <DivOrgBtnClose>
        <BtnClose
          onClick={() => {
            props.setShowAnimation(false);
            props.setTrueAnimation(false);
          }}
        >
          <Close />
        </BtnClose>
      </DivOrgBtnClose>

      <TitleAnimation>Venda realizada com sucesso</TitleAnimation>
      <DivOrgInfoAnimation>
        <DivOrgTable ref={printRef} papersType={typePaper}>
          <DivOrgInfoCompany>
            <DivInfoCompany>
              <InfoCompany>Nome da Empresa: WorldSoft</InfoCompany>
              <InfoCompany>Numero do Pedido: 654521</InfoCompany>
              <InfoCompany>CNPJ: 03.049.303/0001-97</InfoCompany>
              <InfoCompany>Endereço: Av Pio doze 889</InfoCompany>
            </DivInfoCompany>
            <DivInfoClient>
              <InfoCompany>Cliente: Henrique</InfoCompany>
            </DivInfoClient>
          </DivOrgInfoCompany>
          <DivOrgTitle>
            <TitlePrint>Nota Não fiscal</TitlePrint>
          </DivOrgTitle>
          <Line />
          <Table>
            <TableHead>
              <TrTable>
                <ThTable>Items</ThTable>
                <ThTable>Cod.</ThTable>
                <ThTableDesc>Desc.</ThTableDesc>
                <ThTable>QTD</ThTable>
                <ThTablePrices>Preço Unitario</ThTablePrices>
                <ThTablePrices>Preço Total</ThTablePrices>
              </TrTable>
            </TableHead>

            <TableBody>
              {orderList.map((itemsCart, index) => {
                return (
                  <TrTable key={index}>
                    <TdTable>{index + 1}</TdTable>
                    <TdTable>{itemsCart.codProd}</TdTable>
                    <TdTableDesc>{itemsCart.nameProduct}</TdTableDesc>
                    <TdTable>{itemsCart.qtd}</TdTable>
                    <TdTablePrices>
                      <PricesFormat
                        placeholder=""
                        displayType="text"
                        value={itemsCart.priceUnit || 0}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale
                        decimalScale={2}
                        prefix={"R$ "}
                      />
                    </TdTablePrices>
                    <TdTablePrices>
                      <PricesFormat
                        placeholder=""
                        displayType="text"
                        value={itemsCart.valueTotalItem || 0}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale
                        decimalScale={2}
                        prefix={"R$ "}
                      />
                    </TdTablePrices>
                  </TrTable>
                );
              })}
            </TableBody>
          </Table>

          <TablePayments>
            <TableFoot>
              <TrPayments>
                <TdPayments>Forma de pagamento</TdPayments>
                <TdValues>{parseName(infoFinishOrder?.formPayment)}</TdValues>
              </TrPayments>
              <TrPayments>
                <TdPayments>Valor do Frete</TdPayments>
                <TdValues>
                  <PricesFormat
                    displayType="text"
                    value={infoFinishOrder?.valueDelivery}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"R$ "}
                  />
                </TdValues>
              </TrPayments>
              <TrPayments>
                <TdPayments>Total do pedido</TdPayments>
                <TdValues>
                  <PricesFormat
                    displayType="text"
                    value={infoFinishOrder?.valueWithDiscount}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"R$ "}
                  />
                </TdValues>
              </TrPayments>
              <TrPayments>
                <TdPayments>Valor Pago</TdPayments>
                <TdValues>
                  <PricesFormat
                    displayType="text"
                    value={infoFinishOrder?.valueClientPayed}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"R$ "}
                  />
                </TdValues>
              </TrPayments>
              <TrPayments>
                <TdPayments>Troco</TdPayments>
                <TdValues>
                  <PricesFormat
                    displayType="text"
                    value={infoFinishOrder?.valueChange}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"R$ "}
                  />
                </TdValues>
              </TrPayments>
            </TableFoot>
          </TablePayments>
        </DivOrgTable>
        {/* </DivOrgPrint> */}
      </DivOrgInfoAnimation>
      {/* <DivOrgLoading>
        <ClipLoader speedMultiplier={3} />
      </DivOrgLoading> */}
      {/* <StatusOrder>Status do pedido: {finishOrder.message}</StatusOrder> */}
      <DivOrgSelect>
        <SelectPaper
          defaultValue={0}
          onChange={(e) => setTypePaper(e.target.value)}
        >
          <OptionsPaper value={true}>A4</OptionsPaper>
          <OptionsPaper value={false}>Cupom</OptionsPaper>
        </SelectPaper>
      </DivOrgSelect>

      <DivOrgBtnOrder>
        <BtnNewOrder to="/comercial">Novo Pedido</BtnNewOrder>
        <BtnPrint onClick={printOrder}>Imprimir Comprovante</BtnPrint>
        {/* <BtnMail>Enviar por E-mail</BtnMail> */}
      </DivOrgBtnOrder>
    </DivOrderAnimation>
  );
}
