import styled from "styled-components";
import { Colors, Phone_media } from "../../../variable";
import { NumericFormat as Numeric } from "react-number-format";

export const DivSearchItemOut = styled.div`
  width: 65%;
  height: 500px;
  background: ${Colors.BackgroundColors.BkComponent};
  border-radius: 25px;
  margin: 0px 10px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${Phone_media.Phone_table}
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 650px;
  }
`;
export const TitleItemOut = styled.h3``;
export const DivFilter = styled.div`
  width: 100%;
  display: ${({ show }) => (show ? "none" : "flex")};
  /* margin: 5px; */
  padding: 5px;
`;
export const DivOrgFilter = styled.div`
  width: 100%;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const DivBtnSearch = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    justify-content: center;
  }
`;

export const DivOrgInputs = styled.div`
  width: 100%;
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FilterLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 10px; */
  font-size: 0.9rem;
`;
export const FilterInput = styled.input`
  width: 35%;
  height: 30px;
  display: flex;
  border-radius: 25px;
  padding: 10px;
  font-size: 0.9em;
  margin-right: 10px;
`;
export const CodInput = styled(Numeric)`
  font-size: 0.69rem;
  text-align: center;
`;
export const InputFilter = styled.input`
  width: 25%;
  height: 30px;
  display: flex;
  border-radius: 25px;
  padding: 10px;
  margin-right: 10px;
  font-size: 0.9rem;
`;
export const InputDate = styled.input`
  width: 25%;
  height: 30px;
  display: flex;
  border-radius: 25px;
  padding: 10px;
  font-size: 0.9em;
  /* margin-right: 10px; */
`;

export const BtnSearch = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: ${Colors.ButtonsColors.Search};
  cursor: pointer;

  &:active {
    background-color: ${Colors.ButtonsColors.Search};
    box-shadow: 0 5px ${Colors.ButtonsColors.ShadowButton};
    transform: translateY(4px);
  }
`;

export const BtnCancel = styled.button`
  width: 30px;
  border: 1px solid black;
  height: 30px;
  border-radius: 100%;
  background-color: ${Colors.ButtonsColors.Canceled};
  cursor: pointer;

  &:active {
    background-color: ${Colors.ButtonsColors.Canceled};
    box-shadow: 0 5px ${Colors.ButtonsColors.ShadowButton};
    transform: translateY(4px);
  }
`;

export const TableItemOut = styled.div`
  width: 100%;
  height: 85%;
  padding: 10px;
  background-color: ${Colors.BackgroundColors.BkTable};
  border-radius: 25px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  overflow: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: flex;
    width: 12px;
    @media screen and (min-width: 320px) and (max-width: 768px) {
      width: 6px;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px ${Colors.ButtonsColors.Actions};
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px;
  }
`;
export const DivItemOut = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.Text.White};
  border-radius: 25px;
  margin-bottom: 10px;
  padding: 5px;
`;
export const DivInfo = styled.div`
  width: 40%;
  padding: 3px;
  display: flex;
  flex-direction: column;
`;

export const DivOrgId = styled.div`
  width: 8%;
`;

export const IdItemOut = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: ${Colors.BackgroundColors.BkComponent};
  border: 1px solid ${Colors.Text.Black};
  border-radius: 100%;
`;
export const TypeItemOut = styled.p`
  font-size: 0.77rem;
`;
export const CodItemOut = styled.p`
  font-size: 0.7rem;
`;
export const DivItemOutInfo = styled.div`
  width: 50%;
  border: 1px solid black;
  border-radius: 25px;
  margin: 0 5px;
  padding: 2px;
  display: flex;
  justify-content: center;
`;
export const InfoItemOut = styled.p`
  font-size: 0.69rem;
  text-align: center;
`;
export const DivItemOutEdit = styled.div`
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const BtnEdit = styled.button`
  width: 30%;
  height: 90%;
  padding: 5px;
  background-color: ${Colors.ButtonsColors.Confirm};
  color: black;
  border-radius: 100%;

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0px 3px ${Colors.ButtonsColors.ShadowButton};
    color: ${Colors.Text.White};
  }
`;
export const BtnRemove = styled.button`
  width: 30%;
  height: 90%;
  padding: 5px;
  background-color: ${Colors.ButtonsColors.Canceled};
  color: black;
  border-radius: 100%;

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0px 3px ${Colors.ButtonsColors.ShadowButton};
    color: ${Colors.Text.White};
  }
`;
export const BtnView = styled.button`
  width: 30%;
  height: 90%;
  padding: 5px;
  background-color: ${Colors.ButtonsColors.Invoice};
  color: black;
  border-radius: 100%;

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(2px);
    box-shadow: 0px 3px ${Colors.ButtonsColors.ShadowButton};
    color: ${Colors.Text.White};
  }
`;

export const DivOrgLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivOrgData = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 1px; */
`;
export const DivOrgQtd = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const DivOrgPrice = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const DivOrgCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
