import styled from "styled-components";
import { Colors, Phone_media, Tablet_media } from "../../variable";

export const DivCashier = styled.div`
  width: 1100px;
  height: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${Colors.BackgroundColors.BkDiv};
  border-radius: 25px;
  ${Phone_media.Phone_column}
  ${Tablet_media.Tablet_column}
`;

export const TitleCashier = styled.h1`
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin: 5px auto;
  padding-top: 10px;
`;

export const DivSpentRow = styled.div`
  height: 100%;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Phone_media.Phone_column}
  ${Tablet_media.Tablet_column}
`;

export const DivSpentRowTwo = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
