import React, { useEffect, useState } from "react";
import {
  DivRep,
  DivReport,
  ReportOne,
  ReportTitle,
  ReportValue,
  BtnMore,
  TitleStatusReport,
  DivOrgLoading,
  DivOrgTitle,
} from "./StatusReportStyle";
import { ClipLoader } from "react-spinners";

import { EffectFlip, Pagination, Navigation } from "swiper/modules";

export default function SimpleReport(props) {
  const allRegisters = props.dataInfoHome?.allRegisters;

  const [loading, setLoading] = useState(true);
  const [showList, setShowList] = useState();
  const [listRegisters, setListRegisters] = useState([]);

  const createCard = (dataRegisters) => {
    setShowList(true);
    if (showList || dataRegisters) {
      setListRegisters(dataRegisters);
    }
  };

  useEffect(() => {
    createCard(allRegisters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRegisters, listRegisters]);

  setTimeout(() => {
    setLoading(false);
  }, 1500);

  return (
    <DivRep>
      <DivOrgTitle>

      <TitleStatusReport>Cadastros</TitleStatusReport>
      </DivOrgTitle>
      {/* <DivCard> */}
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} />
          </DivOrgLoading>
        ) : (
          <DivReport
            effect={"flip"}
            grabCursor={true}
            pagination={true}
            loop={true}
            navigation={true}
            modules={[EffectFlip, Pagination, Navigation]}
          >
            {listRegisters.map((registersInfo, index) => {
              return (
                <ReportOne key={index}>
                  <ReportTitle>{registersInfo?.nameRegister}</ReportTitle>
                  <ReportValue>{registersInfo?.countRegister || 0}</ReportValue>

                  <BtnMore to={registersInfo?.path}>Detalhes</BtnMore>
                </ReportOne>
              );
            })}
          </DivReport>
        )}
      {/* </DivCard> */}
    </DivRep>
  );
}
